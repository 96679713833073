.ship_filter_area {
    width: -webkit-fill-available;
    margin-top: 10px;
    background-color: #F7F7F7;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* overflow: hidden; */
}

.filters_icons_area {
    display: flex;
}

.ship_filter_area_countries {
    height: 40px;
    margin-left: 12.5px;
    display: flex;
}

.ship_filter_area_countries svg {
    margin: 7.5px
}

.ship_filter_area_cities {
    display: flex;
    flex-direction: row;
    margin-left: 2.5px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 24px;
}

.ship_filter_area_cities .city_item_selected {
    margin-bottom: 1px;
    margin-top: 1px;
    border-radius: 5px;
    background-color: #E8E8E8;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.ship_filter_area_cities .city_item {
    margin-bottom: 1px;
    margin-top: 1px;
    border-radius: 5px;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.ship_filter_area_cities .city_item_selected .city_name {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
}

.ship_filter_area_cities .city_item_selected .city_count {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    margin-left: 5px;
    font-family: "SF-Pro-Rounded-light"
}

.ship_filter_area_cities .city_item .city_name {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light"
}

.ship_filter_area_cities .city_item .city_count {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    margin-left: 5px;
    font-family: "SF-Pro-Rounded-light"
}

.ship_filter_area_cities .with_line {
    display: flex;
}

.ship_filter_area_cities .with_line .line {
    margin-left: 10px;
    height: 14px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0.5px solid #CECDCD;
}

.search_icon {
    margin-left: calc(100% - 416);
}

.search_icon svg {
    margin-top: 13px;
    margin-bottom: 12px;
    margin-right: 20px;
}

.search_bar_ship {
    display: flex;
    margin-left: 2.5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    background-color: #F0F0F0;
    border-radius: 10px;
    width: -webkit-fill-available;
    flex-direction: row;
    justify-content: space-between;
    animation-name: searchslideIn;
    animation-duration: 0.25s;
    animation-direction: reverse;
}

@keyframes searchslideIn {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

.search_bar_ship .search_area {
    display: flex;
}

.search_bar_ship .search_area svg {
    margin-left: 10px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    display: flex;
}

.search_bar_ship .cancel_search_area {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 12px;
    display: flex;
}

.input_placeholder_ship {
    margin-top: 7.5px;
    margin-bottom: 5.5px;
    margin-left: 5px;
    font-family: "SF-Pro-Rounded-light";
    font-size: 13px;
    letter-spacing: calc((25 / 1000) * 1em);
    text-align: end;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #CECDCD;
    display: flex;
}

.filter_count_area {
    display: flex;
    height: 14px;
    margin-top: 19px;
    margin-bottom: 19px;
}

.filter_count_area_total {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-right: 11px;
    font-family: "SF-Pro-Rounded-regular";
    color: #5D5D5D;
}

.country_filter_group {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "SF-Pro-Rounded-light";
    color: #5D5D5D;
}

.country_filter_group_city {
    display: flex;
}

.country_filter_group_city .city_name {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
}

.country_filter_group_city .city_count {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
}

.filter_count_area .filter_icon svg {
    height: 10px;
    width: 10px;
    margin-bottom: 2px;
    margin-top: 2px;
}

.shipment_main_statuses {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: -webkit-fill-available;
}

.shipment_main_statuses .status_normal:not(:last-child) {
    margin-right: 29px;
}

.shipment_main_statuses .status_active:not(:last-child) {
    margin-right: 15px;
}

.shipment_main_statuses .status_not_active:not(:last-child) {
    margin-right: 5px;
}

.shipment_main_statuses .status_active:not(:first-child) {
    margin-left: 15px;
}

.shipment_main_statuses .status_not_active:not(:first-child) {
    margin-left: 5px;
}

.shipment_main_statuses .status_normal {
    width: -webkit-fill-available;
    height: 50px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    min-width: 256px;
}

.shipment_main_statuses .status_active {
    width: -webkit-fill-available;
    height: 50px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.shipment_main_statuses .status_not_active {
    width: fit-content;
    height: 50px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.shipment_main_statuses .status_normal .status_icon {
    margin-left: 10px;
    margin-right: 15px;
}

.shipment_main_statuses .status_normal .status_icon svg {
    margin-top: 15px;
    margin-bottom: 15px;
}

.shipment_main_statuses .status_normal .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_normal .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
}

.shipment_main_statuses .status_normal .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_normal .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.shipment_main_statuses .status_active .status_icon {
    margin-left: 10px;
    margin-right: 15px;
}

.shipment_main_statuses .status_active .status_icon svg {
    margin-top: 15px;
    margin-bottom: 15px;
}

.shipment_main_statuses .status_active .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_active .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
}

.shipment_main_statuses .status_active .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_active .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
}

.shipment_main_statuses .status_not_active .status_icon {
    margin-left: 10px;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_icon svg {
    margin-top: 15px;
    margin-bottom: 15px;
}

.shipment_main_statuses .status_not_active .status_info {
    margin-top: 8px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: column;
}

.shipment_main_statuses .status_not_active .status_info .status_info_name {
    margin-bottom: 3px;
    font-size: 12px;
    font-family: "SF-Pro-Rounded-regular";
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: calc((25 / 1000) * 1em);
}

.shipment_main_statuses .status_not_active .status_info .status_info_info {
    display: flex;
    flex-direction: row;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages .status_info_info_packages_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-regular";
    margin-left: 5px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_packages svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products {
    display: flex;
    margin-right: 10px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products svg {
    margin-top: 2px;
    margin-bottom: 2px;
}

.shipment_main_statuses .status_not_active .status_info .status_info_info .status_info_info_products .status_info_info_products_num {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 5px;
}