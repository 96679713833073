.editing-table-demo .table-data {
    background-color: white;
    padding: 1px 20px;
    margin-top: 20px;
}

.validate-button {
    border: 1px solid #404040;
    color: #404040;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
}

.validate-button:hover {
    background-color: #404040;
    color: #fff;
}

.top-element {
    width: 68%;
    padding: 5px;
    border-radius: 5px;
    border: 0.5px solid #ddd;
    margin-bottom: 8px;
    margin-top: 10px;
}

.trans {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

}

.ka-cell {
    padding: 8px 20px;
    line-height: 29px;
    width: auto;
    overflow-wrap: anywhere;
    color: #353C44;
}