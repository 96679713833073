.qr-container {
    display: flex;
}

.job-emp {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-left: 25px;
    height: 100%;

}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.dept-emp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.job-name {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #5d5d5d;
}

#p8 tspan {
    font-size: 8px;
}

.jop-code {
    color: #5D5D5D;
    font-size: 18px;
}

.input-label {
    color: #8E8E8E;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
}

.locat_static svg {
    position: static !important;
}

.date-emp {
    z-index: 899999999;
}

.input-sync {
    z-index: 999999999999;

}

.emp-item,
.view-body {
    display: flex;
    justify-content: flex-start;
}

.view-body {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.emp-item {
    width: 206px;
    height: 268px;
    cursor: pointer;
    border-radius: 20px;
    background-color: #F7F7F7;
    padding-top: 20px;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.emp-name {
    color: #5D5D5D;
    font-size: 14px;
    font-family: "SF-PRO-Rounded";
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-top: 18px;
    display: flex;

}

.emp-item .emp-photo {
    width: 100px;
    display: flex;

    height: 120px;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;

}

.emp-dept {
    font-size: 12px;
    font-family: "SF-PRO-Rounded_light";
    color: #8E8E8E;
    display: flex;
    margin-top: 3px;
}

.emp-dept-icon {
    display: flex;
    align-items: center;
    width: 130px;
    margin-top: 6px;

    justify-content: center;
}

.emp-info {
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
}

.emp-job-type,
.emp-region {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.emp-job-type span {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #8E8E8E;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.emp-region span {
    font-family: "SF-Pro-Rounded";
    font-size: 10px;
    color: #8E8E8E;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.emp-job-type {
    margin-left: 11px;
}

.emp-job-type svg {
    height: 10px;
    width: 10px;
}

.filter-elem {
    width: 0px;
    display: flex;
    align-items: center;
    height: 30px;
    flex-direction: row;
    overflow: hidden;
    transition: all 0.6s ease;
    white-space: nowrap;
}

.active-filt {
    width: 100%;
}

.filter-opt {
    padding: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    max-height: 25px;
    font-family: "SF-PRO-Rounded_light";
    color: #5D5D5D;
    background-color: transparent;
    border-radius: 5px;
    font-size: 10px;
}

.filter-opt span {
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

    width: auto;
    max-height: 25px;
    font-family: "SF-PRO-Rounded_light";
    color: #5D5D5D;
}

.active-filte {
    background-color: #E8E8E8;
}

.avatar-img-emp {
    width: 43px;
    height: 50px;
    border-radius: 10px;
    position: relative;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}

.avatar-img-emp::before {
    position: absolute;
    top: 0px;
    content: "";
    z-index: 9999;
    left: 0px;
    width: 100%;
    height: 100%;
    box-shadow: inset hsla(0, 0%, 100%, 0.521) 0px 3px 6px;
}

.avatar-img-emp img {
    width: 43px;
    height: 50px;
    border-radius: 10px;
}

.emp-name-mod {
    color: #404040;
    margin-left: 9px;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "SF-PRO-Rounded";
}

.input-con {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    font-size: 12px;
    color: #404040;
    display: flex;

    text-align: start;
    height: 20px;
    width: 100%;

    border-bottom: #CECDCD 0.5px solid;
}

.input-con input {
    width: 35px !important;
    margin: 0 !important;
    outline: none;
    border: none !important;
    background: transparent;
    font-size: 12px;
    color: #404040;
    text-align: start;
    height: 20px;
    width: 100%;
}

.mic-ico svg {
    position: static !important;
}

.inpt-text-helper {
    font-size: 12px;
    font-family: "SF-PRO-Rounded";
    color: #404040;
    left: 17px;
    position: absolute;
    text-transform: capitalize;
}

.active-emp {
    background-color: #F3FAF2;
}

.status_emp {
    display: none;
}

.status_emp {
    display: flex;
    width: 127px;
    justify-content: space-between;
    margin-top: 8px;
}

.deactive-emp {
    background-color: #FAF2F2;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
}

.deactive-emp-status {
    display: none;
}

.deactive-emp .deactive-emp-status {
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
}

.status_emp_item {
    display: flex;
}

svg g.active_gg text tspan {
    font-family: "SF-PRO-Rounded_light";
    font-size: 10px;
}

.cal.c {
    z-index: 9999;
}

.cal.c.cal::-webkit-calendar-picker-indicator {
    display: none;
}

.break-emp {
    background-color: #FAF6F0 !important;
    position: relative;
}

.break-emp .deactive-emp-status {
    display: flex !important;
    display: flex;
    position: absolute;
    top: 10px;
    left: 12px;
}

.loading {
    position: relative;
    background-color: #e9e9e996;
}

.cir {
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
}

/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
            right top, from(transparent),
            color-stop(rgba(255, 255, 255, 0.2)),
            to(transparent));

    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.dm {

    display: flex;
    flex-direction: column;
    vertical-align: baseline;
    max-height: 100%;
    z-index: 9;
    max-width: 425px;
    overflow-anchor: none;
    position: relative;
    background: #E4DCD4;
    overflow-y: scroll;
    padding: 10px 5% 2pc;
    position: fixed;
    bottom: 30px;
    right: 490px;
    max-height: 30vh;
}

.drafts {
    background-color: #CECDCD !important;
    width: 64px !important;
    color: #404040 !important;
    margin-right: 6px !important;
    font-size: 10px !important;
    font-family: "SF-Pro-Rounded";
    box-shadow: none !important;
    text-transform: capitalize;
}

.modal-top-title .no-dis path {
    fill: #404040 !important;
}

.mar.cityflag {
    height: auto;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.mar.cityflag:not(:first-child) {
    margin-left: 9.5px;
}

.req_type {

    margin-top: 2px;
    background-color: #404040;
    border-radius: 5px;
    color: #fff;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    max-height: 22px;
    width: auto;
}

.modal-icon-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.modal-name-icon {
    margin-left: 5px;
    font-family: "SF-Pro-Rounded";
    font-size: 10px;

}

.pad35 {
    padding-left: 44px !important;
}

.abs-icon {
    position: absolute;
    left: 15.5px;
}

.input-holder {
    position: relative;
    transition: 0.3s;
}

.trf .input-holder input {
    height: 44px;
    display: flex;
    padding-top: 6px;
    align-items: center;
}

.input-holder input::placeholder {
    color: #DDDDDD;
    font-size: 12px;
    font-family: "SF-Pro-Rounded";
}

.input-holder img {
    position: absolute;
    right: 0px;
    bottom: 12px;
}

.input-holder svg {
    position: absolute;
    right: 0px;
    bottom: 16px;

}

.fill-norm path {
    fill: #404040;
}

.icons-ol {
    height: 44px;
}

.holder-option {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.holder-option svg {
    position: static !important;
}

.option-desc {
    margin-left: 9px;
    background-color: #404040;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 10px;
    font-family: "SF-Pro-Rounded";
    color: #FFFFFF;
    max-height: 22px;
}

.holder-option svg:not(.fill-norm) path {
    fill: #5d5d5d;
}

.modal-footer {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-button-transfer {
    font-size: 10px;
    font-family: "SF-Pro-Rounded-med";
    background-color: #5674F0;
    border-radius: 10px;
    cursor: pointer;
    color: #FFFFFF;
    padding: 14px 20px;
}

.modal-button-transfer.disable {
    background-color: #AFBFFD;
}

.modal-icons.trf {
    align-items: flex-start !important;
}

.trf .input-section {
    margin-top: 0px !important;
}

input {
    z-index: 888;
}

.pl,
img {
    z-index: 9999;
    cursor: pointer;
}

.currency-lael {
    font-size: 12px;
    text-transform: uppercase;
    color: #5D5D5D;
    position: absolute;
    bottom: 10px;
}

.amoun input {
    font-family: "SF-Pro-Rounded-med" !important;
}

.input-sync {
    display: flex;
    width: 100%;
    height: auto;
    left: 0px;
    top: 34px;
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 999999999999 !important;
    cursor: pointer;

}

.input-sync-item svg,
.input-sync-item img {
    position: static !important;
}

.input-sync-item:hover {
    background-color: #c2c2c2;
}

.input-sync-item {
    width: 100%;
    margin-top: 2px;
    transition: 0.3s;
    padding: 12px 15px;
    background-color: #DDDDDD;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.input-sync-text {
    font-family: "SF-Pro-Rounded";
    font-size: 14px;
    color: #404040;
    margin-left: 10px;
    text-transform: capitalize;
}

.data-country {
    position: absolute;
    left: 15px;
    bottom: 11px !important;
}

img.data-country {
    bottom: 8px !important;
    border-radius: 3px;
    object-fit: cover;
}

.pad48 input {
    padding-left: 47px !important;
}

.input-sync-item img {
    border-radius: 3px;
    object-fit: cover;

}

input.cal#caland {
    right: 0 !important;
    left: initial !important;
    width: 20%;
    transform: none !important;
    position: absolute;
    padding: 0px !important;
}

svg {
    transition: 0.3s;

}

svg path {
    transition: 0.3s;
}

svg.red-bord path {
    fill: #EA4343;
}

.lang-modal {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
    border: rgba(221, 221, 221, 0.52) 0.5px solid;
}

input#session-date::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    transform: scale(12)
}

input::-webkit-date-and-time-value {
    text-align: right;
}

.spin-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f3f3 !important;
}

.spin-cont.no-tran {
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
}

.contact-method {
    display: flex;
    align-items: center;
    position: absolute;
    top: 19px;
    right: 25.5px;
}

.contact-method svg {
    position: static !important;
    cursor: pointer;
}

.contact-method svg:not(:last-child) {
    margin-right: 10px;
}

.plus-minus {
    position: absolute;
    top: 6px;
    right: -3px;
}

.modal-icon-section.trf-modal {
    width: 100%;
    position: relative;
}

.second-text {
    margin-left: 9px;
    font-size: 10px;
    color: #CECDCD;
    font-family: "SF-PRO-Rounded_light";
}