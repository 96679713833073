.ship_package_filter {
    display: flex;
    height: 20px;
    background-color: #F7F8F3;
    width: calc((100% - 87px)/4);
    min-width: 256px;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
}

.ship_package_filter_child:first-child {
    margin-left: 10px;
}

.ship_package_filter_child:last-child {
    margin-right: 10px;
}

.ship_package_filter_child {
    display: flex;
    margin-top: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ship_package_filter_child_selected {
    display: flex;
    margin-top: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}

.ship_package_filter_child .ship_package_filter_child_icon {
    display: flex;
    margin-top: 1px;
    margin-right: 5px;
}

.ship_package_filter_child .ship_package_filter_child_count {
    margin-top: 1px;
    font-size: 8px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ship_package_filter_child .ship_package_filter_child_count_selected {
    margin-top: 1px;
    font-size: 8px;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-Semibold";
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ship_package_filter_expand {
    display: flex;
    height: 20px;
    background-color: #F7F8F3;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%238E8E8EFF' stroke-width='1' stroke-dasharray='2%2c3' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
    width: calc((100% - 87px)/4);
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-evenly;
    width: -webkit-fill-available;
}

.ship_package_filter_child_expand {
    display: flex;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 14px;
}

.ship_package_filter_child_expand .ship_package_filter_child_icon_expand {
    display: flex;
    margin-top: 2px;
    margin-right: 5px;
    margin-bottom: 2px;
    height: 10px;
}

.ship_package_filter_child_expand .ship_package_filter_child_count_expand {
    font-size: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-top: 1px;
}

.ship_package_filter_child_expand .ship_package_filter_child_name_expand {
    display: flex;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #5D5D5D;
    font-family: "SF-Pro-Rounded-light";
    margin-left: 6px;
}

.node_parent_expand {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    justify-content: center;
}